<script>
import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'dms_budget_fee_form',
      formParentCode: 'CRM20230621000000002',
      rule: [],
      buttons: {
        submit: false,
      },
    };
  },

  methods: {
    setRule(v) {
      const item = v;
      return item;
    },
    formComplete() {
      // 获取详情
      console.log('formConfig', this.formConfig);
      if (this.formConfig.row.id) {
        const requestUrl = '/tpm/dmsbudgetapprovedetail/queryInOutDetail';
        request.post(requestUrl, { id: this.formConfig.row.id }).then((res) => {
          if (res.success) {
            const data = {
              ...res.result,
              ...res.result.budgetRespVo,
              feeBudgetCode1: res.result.adjustBudgetRespVo.feeBudgetCode,
              ext111: res.result.adjustBudgetRespVo.ext1,
              budgetSubjectsName1: res.result.adjustBudgetRespVo.budgetSubjectsName,
              customerName1: res.result.adjustBudgetRespVo.customerName,
              ext81: res.result.adjustBudgetRespVo.ext8,
              orgName1: res.result.adjustBudgetRespVo.orgName,
              productLevelName1: res.result.adjustBudgetRespVo.productLevelName,
            };
            this.setValue(data);
          }
        });
      }
    },
  },
};
</script>
