import BasePage from '@/found/components/table_page';
import Form from '../form/form.vue';
import DetailForm from '../form/detail.vue';
import AdjustmentForm from '../form/form-edit-adjust';

export default {
  name: 'investment_type',
  extends: BasePage,
  data() {
    return {};
  },
  components: {
    Form,
    DetailForm,
    AdjustmentForm,
  },
  created() {
    this.getConfigList('dms_fee_budget');
  },
  methods: {
    modalClick({ val, row }) {
      if (val.code === 'view') {
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };
        this.modalConfig.title = val.name || '查看';
        this.openFull();
      }
      if (val.code === 'check_detail') {
        this.formName = 'DetailForm';
        this.modalConfig = {
          title: '明细',
          width: '80%',
        };
        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
          budgetCode: row.feeBudgetCode,
        };
        this.openDrawer();
      }
      if (val.code === 'adjustment') {
        this.formName = 'AdjustmentForm';
        this.modalConfig = {
          title: '调整',
          width: '60%',
        };

        this.formConfig = {
          ...this.formConfig,
          code: val.code,
          row,
        };

        this.openDrawer();
      }
    },
    // 保存回调
    onSubmit(params) {
      const configParams = {
        ...params,
        code: 'approve',
      };

      this.$emit('approve', configParams);
    },
  },
};
