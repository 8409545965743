import BasePage from '@/found/components/table_page';

export default {
  name: 'detail',
  extends: BasePage,
  props: {
    paramsProps: Object,
  },
  data() {
    return {};
  },
  methods: {
    afterGetList() {
      this.exportParams = this.paramsProps;
      return true;
    },

    // setColumn(col) {
    //   const rowData = col;
    //   if (rowData.field === 'attachmentList') {
    //     rowData.type = 'html';
    //     rowData.formatter = ({ row }) => {
    //       const attachmentList = ((row.attachmentList || [])[0] || {});
    //       return `<a style='color:red' href='${attachmentList.url || ''}'>${attachmentList.fileName || ''}</a>`;
    //     };
    //   }
    //   return rowData;
    // },
  },
  created() {
    this.getConfigList('dms_budget_fee_detail');
  },
};
